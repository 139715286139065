@use '@angular/material' as mat;

@import './components/profile-panel/profile-panel.theme';
@import './components/impact-areas-info-panel/impact-areas-info-panel.theme';
@import './components/community-info-panel/community-info-panel.theme';
@import './components/impact-link/impact-link.component.theme';
@import 'components/reminder-panel/reminder-panel.theme';
@import './components/pillar-link/pillar-link.theme';
@import './components/presented-badge/presented-badge.theme';

@mixin hub-dashboard-theme($theme) {
  hub-dashboard {
    @include hub-impact-areas-info-panel($theme);
  }

  @include hub-profile-panel-theme($theme);
  @include hub-community-info-panel($theme);
  @include hub-impact-link($theme);
  @include hub-reminder-panel($theme);
  @include hub-pillar-link($theme);
  @include hub-presented-badge($theme);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  hub-dashboard {
    .hub-page-footer {
      background-color: $foreground-base;
      color: $card;
    }

    .footer-text {
      color: $card;
    }
  }
}
