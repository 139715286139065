@use 'hub-variables' as vars;
@use '@angular/material' as mat;

@mixin hub-area-score-selector($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $tooltip: mat.get-color-from-palette($background, tooltip);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  hub-area-score-selector {
    .score-selector {
      .score-button {
        border-color: $selected-disabled-button;
        background-color: $card;
        color: $foreground-text;

        &.button-disabled {
          pointer-events: none;
          color: $selected-disabled-button;
        }
      }
      .score-button:not(.button-disabled):hover {
        background: var(--score-color);
        color: $card;
      }
    }

    .point {
      background-color: $selected-disabled-button;

      &.active {
        background-color: $tooltip;
      }
    }

    .score-selector > .point {
      background-color: var(--score-color);
      opacity: 0.2;
    }

    .score-selector > .point.preview {
      background-color: var(--score-color);
      opacity: 0.5;
    }

    .score-selector > .point.active,
    .score-selector > .point.active.preview {
      background-color: var(--score-color);
      opacity: 1;
    }
  }

  @media screen and (max-width: map-get(vars.$grid-breakpoints, sm)) {
    hub-area-score-selector {
      .score-selector .score-button:not(.button-disabled):hover {
        background-color: $card;
        color: inherit;
      }

      .score-selector .score-button:not(.button-disabled):active {
        background: var(--score-color);
        color: $card;
      }
    }
  }
}
