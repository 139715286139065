@use '@angular/material' as mat;

@mixin hub-presented-badge($theme) {
  $background: map-get($theme, background);

  $card: mat.get-color-from-palette($background, card);
  $focused-button: mat.get-color-from-palette($background, focused-button);

  hub-presented-badge {
    .presented-badge {
      background-color: $card;
      box-shadow: 0 4px 40px 0 $focused-button;
    }
  }
}
