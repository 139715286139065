@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@mixin hub-profile-widgets-theme($theme) {
  $background: map-get($theme, background);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  hub-inline-edit-text {
    .editable__text--edit {
      border-color: $selected-disabled-button;
    }

    .editable__text:focus {
      border-color: vars.$job-color;
    }

    .editable__actions button {
      color: $secondary-text;
    }
  }

  hub-profile-pillars-card {
    .edit-actions button {
      color: $secondary-text;
    }
  }

  hub-profile-quote-card {
    .profile-card.quote-card::before {
      background-color: $foreground-base;
      color: $card;
    }
  }
}
