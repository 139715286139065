@use '@angular/material' as mat;

@mixin hub-reminder-panel($theme) {
  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  hub-reminder-panel {
    .reminder__button {
      border-color: $foreground-base;
    }
  }
}
