@use '@angular/material' as mat;

@mixin hub-public-profile-theme($theme) {
  $isDark: map-get($theme, is-dark);

  $foreground: map-get($theme, foreground);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  @if $isDark {
    mat-card.quote-card::before {
      color: #000;
      background-color: #fff;
    }
  }

  hub-public-profile {
    .colored-background {
      color: white;

      @if $isDark {
        // remove if public profile will be only with light theme
        background: radial-gradient(
            82.33% 84.86% at 8.67% 8.55%,
            #00723f 0%,
            #025f89 33.66%,
            #49216a 65.96%,
            #ad5f46 100%
          ),
          #6a6a6a;
      } @else {
        background: linear-gradient(
            135deg,
            rgba(0, 199, 111, 1) 0%,
            rgba(3, 169, 244, 1) 33%,
            rgba(126, 58, 183, 1) 66%,
            rgba(255, 138, 101, 1) 100%
          ),
          #ffffff;
      }
    }

    .profile-top-text {
      color: $foreground-text;
    }

    .profile-top-text em,
    .footer-text {
      color: $card;
    }
  }
}
