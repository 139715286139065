@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@import './components/pillars-list-page/pillars-list-page.theme';

@mixin hub-pillars-page-theme($theme) {
  @include hub-pillars-list-page-theme($theme);

  $background: map-get($theme, background);
  $bg-hover: mat.get-color-from-palette($background, hover);
  $bg-selected-button: mat.get-color-from-palette($background, selected-button);
  $bg-selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $bg-background: mat.get-color-from-palette($background, background);
  $bg-card: mat.get-color-from-palette($background, card);
  $bg-tooltip: mat.get-color-from-palette($background, tooltip);

  $foreground: map-get($theme, foreground);
  $fg-base: mat.get-color-from-palette($foreground, base);
  $fg-secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $fg-text: mat.get-color-from-palette($foreground, text);

  $warn: map-get($theme, warn);
  $warning: mat.get-color-from-palette($warn);

  hub-pillars,
  hub-tech {
    .pillar {
      &.active {
        background-color: $bg-selected-button;
      }

      .icon {
        background-color: $bg-selected-disabled-button;
      }
    }

    .left-nav {
      border-right-color: $bg-selected-button;
    }

    .your-pillars {
      border-bottom-color: $bg-selected-button;
    }

    .more-pillars {
      .colored-dot {
        background-color: vars.$init-animation-color;
      }
    }

    .tag {
      border-color: $bg-selected-disabled-button;
      background-color: $bg-card;
    }

    .pillar:hover:not(.active),
    .syllabus-section .tag:hover {
      background-color: $bg-hover;
    }

    .mission-part {
      background-color: $fg-base;
      color: $bg-card;
    }

    .pillar-heading,
    .pillar-headline {
      color: $bg-card;
    }

    .divider {
      background-color: $warning;
      border-color: $bg-tooltip;
    }

    .left-part {
      background-color: vars.$init-animation-color;
    }

    hub-pillars-world-card {
      .card {
        border-color: $bg-selected-button;
        background-color: $bg-background;
      }

      .card-mark {
        color: $bg-card;
      }

      .card-source {
        color: $fg-secondary-text;
      }
    }

    .dive-deeper {
      .dive-title {
        color: $bg-card;
      }
    }

    .description-resources {
      .description-resources-title {
        color: $fg-text;
      }

      a {
        color: $fg-text;
      }
    }

    .mobile-nav {
      color: $bg-card;
    }

    .mobile-nav__browse-all {
      color: $fg-base;
    }
  }
}
