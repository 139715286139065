@use '@angular/material' as mat;

@mixin hub-video-button-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);

  hub-video-button {
    mat-card.mat-card:not([class*='mat-elevation-z']) {
      box-shadow: 0 4px 40px 0 mat.get-color-from-palette($foreground, base, 0.1);
    }

    .preview-video {
      .play-icon {
        color: $card;
      }
    }
  }
}
