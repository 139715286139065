@mixin link-color($color) {
  a {
    color: $color;

    &:visited,
    &:hover,
    &:active {
      color: $color;
    }
  }
}

@mixin transparent-button() {
  color: #000;
  border: 1px solid #000000;
  border-radius: 24px;
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
}

@mixin black-button() {
  color: #fff;
  background-color: #000;
  border: 1px solid #000000;
  border-radius: 24px;
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 24px;
}
