@use '@angular/material' as mat;

@mixin hub-video-overlay-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);

  hub-video-overlay {
    .close-icon {
      color: $card;
      background-color: mat.get-color-from-palette($foreground, base, 0.05);
    }
  }
}
