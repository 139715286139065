@use '@angular/material' as mat;

@mixin hub-pillars-selector($theme) {
  $background: map-get($theme, background);

  $card: mat.get-color-from-palette($background, card);
  $disabled-button-toggle: mat.get-color-from-palette($background, disabled-button-toggle);

  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $tooltip: mat.get-color-from-palette($background, tooltip);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  $warn: map-get($theme, warn);
  $warning: mat.get-color-from-palette($warn);

  hub-pillars-selector {
    .pillar-checkbox {
      background-color: $card;

      &:active {
        background-color: $disabled-button-toggle;
      }
    }

    .pillar-checkbox__icon,
    .pillar-checkbox__content {
      border-color: $selected-disabled-button;
    }

    mat-checkbox.mat-checkbox-checked .mat-checkbox-background {
      background-color: $tooltip !important;
    }

    .edit-popup {
      background-color: rgba($color: $foreground-base, $alpha: 0.6);
    }

    .popup-error {
      color: $warning;
    }
  }
}
