@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@mixin hub-camera-lens-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $dialog-background: mat.get-color-from-palette($background, card, 0.07);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  $warn: map-get($theme, warn);
  $warning: mat.get-color-from-palette($warn);

  .camera-dialog .mat-dialog-container {
    background-color: $dialog-background;
  }

  hub-camera-lens {
    .stop-button {
      background-color: $card !important;

      .stop-icon {
        background-color: $foreground-base;
      }
    }

    .mat-icon-button.record-button .mat-icon {
      color: $warning;
    }

    .approve-controls .mat-mini-fab {
      color: $card;
    }

    .timer {
      color: $card;

      &.recording {
        background-color: $warning;
      }
    }

    .close-button {
      color: $card;
    }
  }
}
