@use '@angular/material' as mat;
@import 'shared/variables';
@import '@angular/material/theming';

@mixin contextual-snack-bar-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);

  $err: map-get($theme, warn);
  $warning: mat.define-palette(mat.$orange-palette);

  eth-contextual-snack-bar {
    &.error {
      background-color: mat.get-color-from-palette($err, 500);
    }

    &.warning {
      background-color: mat.get-color-from-palette($warning, 500);
    }

    &.success {
      background-color: $success-green;
    }

    &.info {
      background-color: mat.get-color-from-palette($primary, 500);
    }

    &.s4e-survey {
      background-color: #323232;
      mat-icon {
        color: #ff4081;
      }
    }
  }
}
