@use '@angular/material' as mat;

@mixin hub-profile-impact-areas-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);
  $primary-default: mat.get-color-from-palette($primary);
  $accent-default: mat.get-color-from-palette($accent);

  hub-profile-impact-areas {
    .profile-impact-areas__legend .legend-icon {
      &.primary {
        background-color: $primary-default;
      }

      &.accent {
        background-color: $accent-default;
      }
    }

    .edit-area-btn {
      color: $secondary-text;
    }
  }
}
