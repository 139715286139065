@use '@angular/material' as mat;

@mixin hub-profile-video-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $overlay-background: mat.get-color-from-palette($background, card, 0.7);
  $disabled-button-toggle: mat.get-color-from-palette($background, disabled-button-toggle);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);
  $foreground-text: mat.get-color-from-palette($foreground, text);
  $opacity-card: mat.get-color-from-palette($foreground, base, 0.3);

  hub-profile-video,
  hub-button-guide {
    .what-sust-mean {
      color: $foreground-text;

      span {
        background-color: $opacity-card;
      }

      &.white {
        color: $card;
      }
    }

    .record-button {
      background-color: $foreground-base;
      border-color: $foreground-base;
      color: $card;
    }

    video {
      background-color: $disabled-button-toggle;
    }

    .play-button {
      background-color: $card !important;

      .mat-icon {
        color: $foreground-base;
      }
    }

    .delete-video {
      background-color: $card;
      color: $foreground-base;
    }

    .no-video-wrapper {
      background-color: $disabled-button-toggle;
    }

    .safari-no-video {
      background-color: $disabled-button-toggle;

      .safari-no-video-message {
        color: $foreground-text;

        .heading {
          color: $foreground-text;
        }

        .re-record-button {
          background-color: $foreground-base;

          .mat-icon {
            color: $card;
          }

          span {
            color: $card;
          }
        }
      }
    }

    .camera-mic-not-available {
      background-color: $disabled-button-toggle;

      .camera-mic-not-available-message {
        .heading {
          color: $foreground-text;
        }

        .desc {
          color: $foreground-text;
        }
      }
    }

    .white-overlay {
      background-color: $overlay-background;
    }

    .webcam-icon {
      color: $foreground-text;
    }

    .video-in-process {
      background-color: $card;
      color: $foreground-text;
    }

    .video-flagged {
      color: $foreground-text;

      a {
        color: $foreground-text;
      }
    }

    .loading.no-video-wrapper {
      background-color: $disabled-button-toggle;
    }
  }
}
