@mixin button-size-shorthand($height, $font-size) {
  &.mat-button,
  &.mat-raised-button,
  &.mat-stroked-button {
    padding-left: $height * 0.5 + 4px;
    padding-right: $height * 0.5 + 4px;
    border-radius: $height * 0.5 + 1;
    font-size: $font-size;
  }

  &.mat-button,
  &.mat-raised-button {
    line-height: $height;
  }

  &.mat-stroked-button {
    // the line height is decreased because of the border that takes 2px of vertical space
    line-height: $height - 2px;
  }
}

button,
a {
  $default-height: 48px;
  $default-font-size: 16px;
  @include button-size-shorthand($default-height, $default-font-size);

  .mat-button-ripple {
    border-radius: $default-height * 0.5 + 1;
  }

  &.button-smaller {
    $smaller-height: 36px;
    $smaller-font-size: 14px;
    @include button-size-shorthand($smaller-height, $smaller-font-size);
  }

  &.button-smallest {
    $smallest-height: 28px;
    $smallest-font-size: 12px;
    @include button-size-shorthand($smallest-height, $smallest-font-size);
  }
  &.close-button.mat-icon-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.mat-raised-button,
.mat-button {
  &.submit-button {
    &:not([class*='mat-elevation-z']),
    &:not([disabled]):active:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }
}
