@use 'hub-variables' as vars;

.hub-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hub-spinner {
  width: 100px;
  height: 100px;
  animation: eth-spinner-linear-rotate 2000ms linear infinite;
  display: block;
  position: relative;

  svg {
    position: absolute;
    transform: rotate(-90deg);
    top: 0;
    left: 0;
    transform-origin: center;
    overflow: visible;

    circle {
      transition-property: stroke;
      animation-duration: 4000ms;
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-iteration-count: infinite;

      fill: transparent;
      transform-origin: center;
      transition: stroke-dashoffset 225ms linear;

      stroke: vars.$gray-input-text;

      animation-name: eth-spinner-stroke-rotate-100;
      stroke-dasharray: 282.743px;
      stroke-width: 10%;
    }
  }
}

@keyframes eth-spinner-linear-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes eth-spinner-stroke-rotate-100 {
  0% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(0);
  }
  12.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(0);
  }
  12.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(72.5deg);
  }
  25% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(72.5deg);
  }
  25.0001% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(270deg);
  }
  37.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(270deg);
  }
  37.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(161.5deg);
  }
  50% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(161.5deg);
  }
  50.0001% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(180deg);
  }
  62.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(180deg);
  }
  62.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(251.5deg);
  }
  75% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(251.5deg);
  }
  75.0001% {
    stroke-dashoffset: 268.606171575px;
    transform: rotate(90deg);
  }
  87.5% {
    stroke-dashoffset: 56.5486677px;
    transform: rotate(90deg);
  }
  87.5001% {
    stroke-dashoffset: 56.5486677px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
  100% {
    stroke-dashoffset: 268.606171575px;
    transform: rotateX(180deg) rotate(341.5deg);
  }
}
