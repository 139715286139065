@use '@angular/material' as mat;

@mixin hub-impact-areas-info-panel($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  hub-impact-areas-info-panel {
    .impact-headline .inverted {
      color: $card;
      background: $foreground-base;
    }

    .impact-round-label {
      border-color: $selected-disabled-button;
    }
  }
}
