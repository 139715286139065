html {
  font-family: sans-serif;
}

body {
  margin: 0;
}
//
// Add the correct display in IE 9-.
// 1. Add the correct display in Edge, IE, and Firefox.
// 2. Add the correct display in IE.
//
article,
aside,
details, // 1
figcaption,
figure,
footer,
header,
main, // 2
menu,
nav,
section,
summary {
  // 1
  display: block;
}
a {
  cursor: pointer;
}

//
// 1. Remove the gray background on active links in IE 10.
// 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
//
a {
  background-color: transparent; // 1
  -webkit-text-decoration-skip: objects; // 2
}
//
// Remove the outline on focused links when they are also active or hovered
// in all browsers (opinionated).
//
a:active,
a:hover {
  outline-width: 0;
}
//
// Remove the border on images inside links in IE 10-.
//
img {
  border-style: none;
}
//
// Hide the overflow in IE.
//
svg:not(:root) {
  overflow: hidden;
}
//
// 1. Change font properties to `inherit` in all browsers (opinionated).
// 2. Remove the margin in Firefox and Safari.
//

button,
input,
optgroup,
select,
textarea {
  font: inherit; // 1
  margin: 0; // 2
}
//
// Show the overflow in IE.
// 1. Show the overflow in Edge.
//

button,
input {
  // 1
  overflow: visible;
}

//
// Remove the default vertical scrollbar in IE.
//

textarea {
  overflow: auto;
}

// Lists
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 10px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
