@use '@angular/material' as mat;

@mixin hub-pillar-link($theme) {
  $background: map-get($theme, background);

  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $disabled-button-toggle: mat.get-color-from-palette($background, disabled-button-toggle);
  $focused-button: mat.get-color-from-palette($background, focused-button);

  hub-pillar-link,
  hub-button-guide {
    .pillar-button {
      background-color: $card;

      &:hover {
        background-color: $disabled-button-toggle;
      }

      &:active {
        background-color: $focused-button;
      }
    }
  }

  .pillar-button__icon,
  .pillar-button__content {
    border-color: $selected-disabled-button;
  }
}
