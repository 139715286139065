// variables
$partnership-color: #92a4fc;
$job-color: #51b5ff;

// text
$gray-input-text: #9aa9bb !default;

// Grid breakpoints

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
) !default;

// Tablet
$tablet-breakpoint: 1024px;

// Color used in script for dynamic colors
$init-animation-color: #1fd3a0;

// Color used in animation
$animation-white: white;

// Global content width limit
$content-width-limit: 1200px;
