@use '@angular/material' as mat;
@use 'hub-variables' as vars;
@use 'hub-mixins' as mixins;

@import './components/area-score-selector/area-score-selector.theme';
@import './components/areas-editor/areas-editor.theme';
@import './components/pillars-selector/pillars-selector.theme';

@mixin hub-profile-theme($theme) {
  @include hub-area-score-selector($theme);
  @include hub-areas-editor($theme);
  @include hub-pillars-selector($theme);

  $isDark: map-get($theme, is-dark);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  @if $isDark {
    mat-card.quote-card::before {
      color: #000;
      background-color: #fff;
    }
  }

  hub-profile {
    .colored-background {
      color: $card;

      @if $isDark {
        background: radial-gradient(
            82.33% 84.86% at 8.67% 8.55%,
            #00723f 0%,
            #025f89 33.66%,
            #49216a 65.96%,
            #ad5f46 100%
          ),
          #6a6a6a;
      } @else {
        background: linear-gradient(
            135deg,
            rgba(0, 199, 111, 1) 0%,
            rgba(3, 169, 244, 1) 33%,
            rgba(126, 58, 183, 1) 66%,
            rgba(255, 138, 101, 1) 100%
          ),
          #ffffff;
      }
    }

    .profile-is-shared-link {
      .share-button.mat-button-base {
        border-color: $foreground-text;
        color: $foreground-text;
      }
    }

    .profile-top-text {
      color: $foreground-text;

      em {
        color: $card;
      }
    }

    .footer-text {
      color: $card;
    }

    .hub-page-footer {
      background-color: $foreground-base;
      color: $card;
    }

    .profile-is-shared-link,
    .profile-is-shared-notification {
      color: vars.$gray-input-text;

      @include mixins.link-color(vars.$gray-input-text);
    }
  }
}
