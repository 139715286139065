@use '@angular/material' as mat;

@mixin hub-areas-editor($theme) {
  $background: map-get($theme, background);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);

  hub-areas-editor {
    .score-item-card {
      border-color: $selected-disabled-button;
    }
  }
}
