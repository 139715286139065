@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@import './components/areas-list-page.theme';

@mixin hub-areas-theme($theme) {
  @include hub-areas-list-page-theme($theme);

  $foreground: map-get($theme, foreground);
  $foreground-text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $selected-button: mat.get-color-from-palette($background, selected-button);
  $app-bar: mat.get-color-from-palette($background, app-bar);

  hub-areas {
    .left-content {
      border-right-color: $selected-button;
    }

    .selection-bar {
      .item.selected {
        background-color: $selected-button;
      }
    }

    .logo-letter {
      color: $card;
      background-color: vars.$init-animation-color;
    }

    .headers {
      .banner {
        background-color: vars.$init-animation-color;

        .desc {
          color: $card;
        }
      }
    }

    .horizontal-card {
      a,
      .wrapper {
        color: $foreground-text;
      }

      .logo mat-icon {
        color: $card;
      }
    }

    .vertical-card {
      a {
        color: $foreground-text;
      }
    }

    .references {
      .number {
        color: vars.$init-animation-color;
      }
    }

    .mobile-nav {
      color: $card;

      .logo-letter {
        color: $card;
      }
    }

    .mobile-nav__browse-all {
      color: $foreground-text;
    }
  }
}
