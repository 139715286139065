@use '@angular/material' as mat;
@use 'hub-mixins' as mixins;

@mixin hub-feedback-card-theme($theme) {
  $background: map-get($theme, background);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);

  $foreground: map-get($theme, foreground);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  hub-feedback-card,
  hub-tech {
    .recommendation-sanded-wrapper {
      border-color: $selected-disabled-button;
    }

    .description-block {
      .promotion-block-description {
        @include mixins.link-color($foreground-text);
      }
    }
  }
}
