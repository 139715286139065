@use '@angular/material' as mat;

@mixin hub-pillars-list-page-theme($theme) {
  $background: map-get($theme, background);
  $bg-app-bar: mat.get-color-from-palette($background, app-bar);
  $bg-selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);

  hub-pillars-list-page {
    .pillars-list-heading {
      background-color: $bg-app-bar;
      border-color: $bg-selected-disabled-button;
    }

    .pillars-list__item:active {
      background-color: $bg-app-bar;
    }
  }
}
