@use '@angular/material' as mat;
@use 'hub-mixins' as mixins;

@import './confirm-delete-dialog/confirm-delete-dialog.theme';

@mixin hub-account-theme($theme) {
  @include hub-confirm-delete-account-dialog-theme($theme);

  $foreground: map-get($theme, foreground);
  $foreground-text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  hub-account,
  hub-tech {
    .form {
      mat-form-field.mat-form-field .mat-form-field-outline {
        color: $foreground-text;
      }
    }

    hub-share-profile-button {
      button.share-button.mat-button-base {
        background: $foreground-text;
        color: $card;
      }
    }

    @include mixins.link-color($secondary-text);

    .description-block {
      color: $secondary-text;
    }

    .action-button {
      border-color: $foreground-text;
    }

    .bottom-text {
      color: $secondary-text;
    }
  }
}
