@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@mixin hub-top-bar-theme($theme) {
  $isDark: map-get($theme, is-dark);

  $background: map-get($theme, background);
  $bg-hover: mat.get-color-from-palette($background, hover);
  $bg-selected-button: mat.get-color-from-palette($background, selected-button);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);
  $fg-base: mat.get-color-from-palette($foreground, base);

  $primary: map-get($theme, primary);
  $middle-color: mat.get-color-from-palette($primary, 500);

  hub-top-bar {
    .logo {
      @if $isDark {
        filter: invert(1);
      }
    }

    .main-menu {
      li > a {
        color: $fg-base;

        &:hover,
        &:focus {
          background-color: $bg-hover;
        }

        &:focus-visible {
          outline: none;
        }

        &.active {
          background-color: $bg-selected-button;
        }
      }
    }

    .slogan {
      span {
        color: vars.$init-animation-color;
      }
    }

    .top-bar_mobile-navigation {
      background-color: $card;
      border-top-color: $bg-selected-button;
      border-bottom-color: $bg-selected-button;
      @include mat.elevation(4, $fg-base, 1);
    }

    .light-text-color {
      color: vars.$gray-input-text;
    }

    .light-text-color a {
      color: vars.$gray-input-text;
    }

    .light-text-color li a {
      color: vars.$gray-input-text;
    }

    .top-bar_settings {
      color: vars.$gray-input-text;
      border-top-color: $bg-selected-button;
      border-bottom-color: $bg-selected-button;
    }
  }

  hub-top-bar,
  .profile-info {
    .user-photo {
      border-color: $bg-selected-button;
      background-color: $middle-color;
      color: $card;
    }
  }
}
