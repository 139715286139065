@use '@angular/material' as mat;

@mixin report-pillar-impact-page-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $is-dark: map-get($theme, is-dark);

  eth-report-pillar-impact-page {
    nav a {
      border: 1px solid lightgray;
      background-color: $white;
    }
  }
}

/*
* This mixin is designed for theming the component with pillar specific colors. Please do not
* include other styles here, except those which use the provided `$palette`.
*
* `$palette` is a regular Angular Material palette consisting of a set of shades of a particular
* color as well as a contrast text color for each shade.
*
* In order this mixin to work, please do the following:
* 1. Wrap any style you put into this mixin by current component's selector.
* 2. Include this mixin in an ancestor component's theme, which actually iterates over pillar
*    indexes (0 to 5) and generates a set of pillar specific styles.
* 3. Make sure that current component gets wrapped by any ancestor component, which has a dynamic
*    class with the current pillar index (something like `pillar-index-2`).
*/
@mixin report-pillar-impact-page-multi-palette-styles($palette) {
  // These are main colors, usually used for background
  $default: mat.get-color-from-palette($palette, default);
  $lighter: mat.get-color-from-palette($palette, lighter);
  $darker: mat.get-color-from-palette($palette, darker);

  // These are contrast colors, usually used for text over the appropriate background
  $default-contrast: mat.get-color-from-palette($palette, default-contrast);
  $lighter-contrast: mat.get-color-from-palette($palette, lighter-contrast);
  $darker-contrast: mat.get-color-from-palette($palette, darker-contrast);

  .pillar-impact-page-content {
    eth-bubble-chart.single-pillar-chart {
      .highcharts-packedbubble-series.highcharts-color-0 path {
        fill: $lighter;
        stroke: $default;
      }

      .parent-bubble {
        border: 1px solid $default;
        background-color: $lighter;
      }

      .point-color-marker {
        color: $default;
      }

      path.highcharts-label-box {
        stroke: $default;
      }

      path.highcharts-point.highcharts-color-0 {
        fill: $default;
        opacity: 0.5;
        stroke: $darker;
      }
    }

    .legend-circle {
      background-color: $lighter;
      border-color: $default;
    }
  }
}
