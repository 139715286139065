@use '@angular/material' as mat;

/*
* This mixin is designed for theming the component with pillar specific colors. Please do not
* include other styles here, except those which use the provided `$palette`.
*
* `$palette` is a regular Angular Material palette consisting of a set of shades of a particular
* color as well as a contrast text color for each shade.
*
* In order this mixin to work, please do the following:
* 1. Wrap any style you put into this mixin by current component's selector.
* 2. Include this mixin in an ancestor component's theme, which actually iterates over pillar
*    indexes (0 to 5) and generates a set of pillar specific styles.
* 3. Make sure that current component gets wrapped by any ancestor component, which has a dynamic
*    class with the current pillar index (something like `pillar-index-2`).
*/
@mixin metric-card-description-multi-palette-styles($palette) {
  // These are main colors, usually used for background
  $default: mat.get-color-from-palette($palette, default);
  $lighter: mat.get-color-from-palette($palette, lighter);
  $darker: mat.get-color-from-palette($palette, darker);

  // These are contrast colors, usually used for text over the appropriate background
  $default-contrast: mat.get-color-from-palette($palette, default-contrast);
  $lighter-contrast: mat.get-color-from-palette($palette, lighter-contrast);
  $darker-contrast: mat.get-color-from-palette($palette, darker-contrast);

  eth-metric-card-description {
    .material-icons:not(.tooltip-icon) {
      color: $default;
    }
  }
}
