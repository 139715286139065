@use '@angular/material' as mat;
@use 'hub-variables' as vars;
@use 'hub-mixins' as mixins;

@mixin hub-layout-theme($theme) {
  $background: map-get($theme, background);

  $accent: map-get($theme, accent);
  $accent-600: mat.get-color-from-palette($accent, 600);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $app-bar: mat.get-color-from-palette($background, app-bar);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);
  $foreground-text: mat.get-color-from-palette($foreground, text);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  hub-page {
    header {
      background: $card;
    }

    .footer-link-container {
      background: $app-bar;
    }

    .footer-links-wrapper {
      .footer-links-block {
        box-shadow: 0 0 40px mat.get-color-from-palette($foreground, base, 0.12);

        &.partnership {
          background: vars.$partnership-color;
        }

        &.job {
          background: vars.$job-color;
        }

        &.community {
          background: $accent-600;
        }
      }
    }

    .footer-links-wrapper {
      @include mixins.link-color($card);
    }

    .terms-and-services-container {
      background: $app-bar;
    }

    .terms-and-services-content {
      color: $secondary-text;
    }

    .terms-and-services-row {
      @include mixins.link-color($secondary-text);
    }

    .full-width {
      color: $foreground-text;
    }

    footer {
      background: $foreground-base;
    }
  }
}
