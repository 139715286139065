@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@import './components/public-profile/public-profile.theme';

@mixin hub-public-theme($theme) {
  @include hub-public-profile-theme($theme);

  $isDark: map-get($theme, is-dark);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  hub-public-header {
    .slogan {
      span.colored {
        color: vars.$job-color;
      }

      a.logo-link {
        color: $foreground-text;
      }

      a.logo-link:visited {
        color: $foreground-text;
      }
    }

    .join-button {
      border-color: $foreground-base;
    }
  }
}
