@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@mixin hub-share-profile-button-theme($theme) {
  $background: map-get($theme, background);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  $warn: map-get($theme, warn);
  $warning: mat.get-color-from-palette($warn);

  hub-share-profile-button {
    button.share-button.mat-button-base {
      border-color: $card;
    }
  }

  hub-social-share-dialog {
    .public-btn {
      background-color: $foreground-base;
      color: $card;
    }

    .share-circle {
      u {
        color: $foreground-base;
      }
    }
  }

  hub-social-unshare-dialog {
    .public-btn {
      background-color: $warning;
      color: $card;
    }
  }

  hub-un-share-profile-button {
    .public-btn {
      background-color: $foreground-base;
      color: $card;

      .share-circle {
        u {
          color: $foreground-base;
        }
      }
    }
  }
}
