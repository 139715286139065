@use '@angular/material' as mat;

@mixin hub-learning-content-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $app-bar: mat.get-color-from-palette($background, app-bar);

  $foreground: map-get($theme, foreground);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  hub-learning-content {
    .learning {
      .panel-row {
        &.row-heading {
          color: $card;
        }

        &.row-body {
          border-bottom-color: $selected-disabled-button;

          .row-directed-by {
            color: $secondary-text;
          }

          .row-link {
            color: $secondary-text;
            background-color: $app-bar;
          }
        }
      }
    }
  }
}
