@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@mixin hub-confirm-delete-account-dialog-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);
  $secondary-text: mat.get-color-from-palette($foreground, secondary-text);

  $warn: map-get($theme, warn);
  $warning: mat.get-color-from-palette($warn);

  hub-confirm-delete-account-dialog {
    .description-block {
      color: $secondary-text;
    }

    .action-line {
      button.mat-button-base.remove-button {
        background: $warning;
        color: $card;
      }
    }
  }
}
