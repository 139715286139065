@use '@angular/material' as mat;

@mixin hub-next-theme($theme) {
  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $selected-button: mat.get-color-from-palette($background, selected-button);
  $focused-button: mat.get-color-from-palette($background, focused-button);

  hub-next {
    section.top {
      background-color: $foreground-base;
      color: $card;

      .title {
        color: $foreground-text;
      }

      .sub-title {
        color: $foreground-text;
      }

      .video-placeholder {
        background-color: $selected-button;
        color: $foreground-text;
        box-shadow: 0 4px 80px $focused-button;

        video {
          background-color: $selected-button;
        }

        .play-icon {
          color: $card;
        }
      }
    }

    .recommendation-container {
      background-color: $selected-disabled-button;
    }
  }
}
