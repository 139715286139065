@use '@angular/material' as mat;

@mixin hub-areas-chart-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  hub-areas-chart {
    svg text {
      fill: $foreground-base;
    }

    .dark-mode svg text {
      fill: $card;
    }
  }
}
