@use '@angular/material' as mat;
@import './shared/shared-components.theme';
@import './features/dashboard/dashboard.theme';
@import './features/pillars/pillars.theme';
@import './features/profile/profile.theme';
@import './features/areas/areas.theme';
@import './features/next/next.theme';
@import './features/public/public.theme';
@import './features/account/account.theme';
@import '../../../libs/shared/src/lib/contextual-snack-bar/components/contextual-snack-bar.theme';

@mixin hub-app-theme($theme) {
  $background: map-get($theme, background);
  $warn: map-get($theme, warn);

  hub-root a,
  hub-root button {
    $foreground: map-get($theme, foreground);
  }

  hub-root {
    $foreground: map-get($theme, foreground);

    background: mat.get-color-from-palette($background, background);
    color: mat.get-color-from-palette($foreground, text);

    // Include the Hub component themes
    @include hub-dashboard-theme($theme);
    @include hub-pillars-page-theme($theme);
    @include hub-next-theme($theme);
  }

  .warning-message-snackbar {
    background-color: mat.get-color-from-palette($warn);
    color: mat.get-color-from-palette($background, background);
  }

  @include hub-profile-theme($theme);
  @include hub-public-theme($theme);
  @include hub-shared-components-theme($theme);
  @include hub-areas-theme($theme);
  @include hub-account-theme($theme);
  @include contextual-snack-bar-theme($theme);
}
