@use '@angular/material' as mat;

@mixin hub-video-confirm-dialog-theme($theme) {
  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  $foreground: map-get($theme, foreground);
  $foreground-base: mat.get-color-from-palette($foreground, base);

  hub-video-confirm-dialog {
    .confirm-btn {
      background-color: $foreground-base;
      color: $card;
    }
  }
}
