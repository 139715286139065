@use '@angular/material' as mat;
@use 'hub-variables' as vars;

@mixin hub-areas-list-page-theme($theme) {
  $foreground: map-get($theme, foreground);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  $background: map-get($theme, background);
  $selected-disabled-button: mat.get-color-from-palette($background, selected-disabled-button);
  $app-bar: mat.get-color-from-palette($background, app-bar);

  hub-areas-list-page {
    .pillars-list-heading {
      background-color: $app-bar;
      border-color: $selected-disabled-button;
    }

    .pillars-list__item:active {
      background: $app-bar;
    }

    .pillars-list__letter {
      color: $foreground-text;
      background-color: vars.$init-animation-color;
    }
  }
}
