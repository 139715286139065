$body: #f9f9fb !default;
$body-color: #000000 !default;

$white: #ffffff !default;
$black: #000000 !default;

$gray-dark: #373a3c !default;
$gray: #666666 !default;
$gray-light: #999999 !default;
$gray-light-plus: #b6b6b6 !default;
$gray-light-muted: #d8dee6 !default;
$grey-border: #b2b2b2 !default;
$grey-border-light: #cccccc !default;
$grey-btn: #c4c4c4 !default;
$grey-medium-light: #d9d9d9 !default;
$gray-blue: #96a7bc;
$grey-secondary: #7a8a9f !default;
$gray-lighter: #eceeef !default;
$gray-lightest: #f7f7f9 !default;
$gray-ripple: #d3d3d3 !default;
$gray-input-text: #9aa9bb !default;
$white-grey: #f3f3f3 !default;
$almost-white: #fafafa !default;
$gray-neutral: #667085 !default;

$gray-table: #e5e5e5;

$success-green: #5cb85c;

// new variables
$green-new: #649914;
$very-light-cyan: #ebeeee;
$dark-blue: #1f2532;
$holder-bg: #ecf0f7;
$cyan: #1acccc;
$report-header: #e0e5ea;
$slide-icon: #f4b400;
$blue-select-color: #08a0ff;
$blue-green-select-color: #00d4d9;
$green-select-color: #18e42c;

// RGBA
$transparent-black-3: rgba(0, 0, 0, 0.3);
$transparent-black-2: rgba(0, 0, 0, 0.2);
$transparent-black-1: rgba(0, 0, 0, 0.1);
$transparent-gray-blue-1: rgba(154, 169, 187, 0.15);
$transparent-gray-blue-8: rgba(154, 169, 187, 0.8);
$transparent-white-1: rgba(255, 255, 255, 0.14);
$transparent-white-3: rgba(255, 255, 255, 0.35);
$transparent-white-6: rgba(255, 255, 255, 0.6);
$transparent-white-8: rgba(255, 255, 255, 0.8);
$transparent-almost-white: rgba(204, 204, 204, 0.2);
$chart-area: rgba(128, 137, 175, 0.25);
$transparent-dark-blue-1: rgba(25, 60, 98, 0.1);
$transparent-dark-blue-5: rgba(25, 60, 98, 0.5);
$transparent-dark-blue-9: rgba(25, 60, 98, 0.9);

$theme-page-grey: $gray-input-text;

// Reporting colors
$report-light-grey: #b0b0b0;
$report-black-1: rgba(0, 0, 0, 0.87);
$report-green: #3deaa6;

// Fonts

// Root to fonts
$font-size-smallest: 10px !default;
$font-size-small: 13px !default;
$font-size-base: 14px !default;
$font-size-height: 16px !default;
$line-height-base: 1.2em !default;
$font-family-base: Roboto, 'Helvetica Neue', sans-serif !default;
$font-weight-base: 400 !default;
$font-size-h1: 32px !default;
$font-size-h2: 28px !default;
$font-size-h3: 24px !default;
$font-size-h4: 20px !default;
$font-size-h5: 16px !default;
$font-size-h6: 14px !default;
$font-size-h-lg: 54px !default;
$font-size-h-md: 50px !default;
$headings-margin-bottom: 14px !default;
$headings-font-family: $font-family-base;
$headings-font-weight: 400 !default;
$headings-line-height: 1.1 !default;
$headings-color: $body-color;
// Links
$link-color: #00f;

// Style anchor elements.
$link-decoration: none !default;
$link-hover-decoration: underline !default;

// Grid breakpoints

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
) !default;

// Grid containers

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
) !default;
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width-base: 40px !default;
$grid-gutter-widths: (
  xs: 20px,
  sm: 30px,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
) !default;
// Helpers

// Indents

$indent-step: 5;
$indent-max: 20;
// MD Cards
$client-card-logo-height: 50px;
$card-content-wrapper-padding: 25px;
// Table
$table-header-font-weight: 500;
$table-cell-padding: 18px 6px;
$table-responsive-cell-padding: 20px 14px;
$table-responsive-bottom-indent: 20px;
$table-border-color: #e3e3e3;
$table-section-title: #f7f7f7;

// Spinner
$loading-spinner-size: 60px;

// Forms
$input-text-focused: #333333 !default;
$wizard-text: #cacaca !default;
$checkbox-height: 30px;
$checkbox-width: 30px;

// Wizard
$form-active-text-color: $input-text-focused;

// Impact
$equivalency-symbol-color: #ededed;

// Ethic logo
$ethic-logo-width: 84px;
$ethic-logo-height: 27px;

//gradients
$ethic-welcome-gradient: linear-gradient(270deg, #00f, #5e5eff);

// Tablet
$tablet-breakpoint: 1024px;
