@use '@angular/material' as mat;

@mixin hub-community-info-panel($theme) {
  $primary: map-get($theme, primary);
  $middle-color: mat.get-color-from-palette($primary, 500);

  $background: map-get($theme, background);
  $card: mat.get-color-from-palette($background, card);

  hub-community-info-panel {
    .community-wrap {
      background-color: $card;
      opacity: 0.6;
    }

    .community-card-headline em {
      color: $middle-color;
    }
  }
}
