@use '@angular/material' as mat;

@mixin hub-impact-link($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $selected-button: mat.get-color-from-palette($background, selected-button);
  $disabled-button-toggle: mat.get-color-from-palette($background, disabled-button-toggle);
  $focused-button: mat.get-color-from-palette($background, focused-button);
  $foreground-text: mat.get-color-from-palette($foreground, text);

  hub-impact-link {
    .impact-link {
      border-color: $selected-button;
      color: $foreground-text;
    }

    .impact-link:hover {
      background: $disabled-button-toggle;
    }

    .impact-link:active {
      background: $focused-button;
    }
  }
}
